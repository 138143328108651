<!-- 报警数据 -->
<template>
    <div class="alarmData">
        <my-Titlebar>
            <template slot="TitlebarLeft">
                <div class="alarmData-titLeft">
                   <p @click="$router.back()">返回</p>
                </div>
            </template>
            <template slot="TitlebarRight">
                <div class="alarmData-titRight">
                    <my-btn text="搜索" @click="searchfun"></my-btn>
                    <my-btn text="批量操作" @click="ResDateFilter('批量操作')"></my-btn>
                    <my-btn text="导出Excel" @click="DowDataExcel"></my-btn>
                </div>
            </template>
        </my-Titlebar>

        <div class="alarmData-contaier">
            <my-tables ref="tableRef" :headers="columns" :requestApi="requestApi"
            data-aos="slide-up" :data-aos-delay="100"
                :total.sync = "total"
                :initParam="initParam" @checkboxFun="checkboxFun" :indexType="true">
                    <template #T_State="{ row }">
                        <div>
                            <el-tag effect="dark" type="danger" size="mini" v-if="row.T_State==3">待处理</el-tag>
                            <el-tag effect="dark" v-else size="mini">已处理</el-tag>
                        </div>
                    </template>
                    <template #right="{ row }">
                        <el-button size="mini" type="primary" @click="SetChuli(row)">处理</el-button>
                        <el-button size="mini" type="primary" @click="Setjilu(row)">记录</el-button>
						<el-button size="mini" type="danger" @click="SetDel(row)">删除</el-button>
                    </template>
                </my-tables>
        </div>
        <my-Modalbox :visible.sync="visible" :titleBox="showBoxtit" iswidth="30%" minHeihts="600px">
            <template slot="modalConter">
                <el-input v-model="PageParam.T_Text" placeholder="处理报警备注" style="margin-top: 50px;"></el-input>
                <div style="display: flex;justify-content: center;gap: 20px;margin-top:40px;">
                    <my-btn style="width: 80px;height: 20px;line-height: 20px;text-align: center;" text="已处理" @click="PageParam.T_Text = '已处理'"></my-btn>
                    <my-btn style="width: 80px;height: 20px;line-height: 20px;text-align: center;" text="天气原因" @click="PageParam.T_Text = '天气原因'"></my-btn>
                    <my-btn style="width: 80px;height: 20px;line-height: 20px;text-align: center;" text="测试" @click="PageParam.T_Text = '测试'"></my-btn>
                </div>
                <div class="model-btn" @click="onSubmitFun">
                    <img src="../../assets/img/btn.png">
                    <label>立即执行</label>
                </div>
            </template>
        </my-Modalbox>
        <el-drawer title="处理记录" :visible.sync="Records" direction="rtl">
            <my-tables ref="RestableRef" :headers="Rescolumns" :initParam="initParam"
            data-aos="zoom-in" data-aos-delay="0" :indexType="true" :pagination="false" :checkbox="false" >
            </my-tables>
        </el-drawer>
        <my-drawers ref="drawersRef" titles="搜索" @searchFun="searchFun" :drawer.sync="drawer"></my-drawers>
    </div>
</template>

<script>
import cards from "../../components/cards.vue";
import cardsRight from "../../components/cardsRight.vue";

import {DevList } from "@/api/equipmentManagement/callthepolice"
import Axios from 'axios'
import fileDownload from 'js-file-download';
import {
	DevicExcel,//导出Excel
    DevicDel,//删除v
    DevicEdit,//处理
} from "@/api/Warning.js"
import { formatDate } from '@/utils/Times.js'
export default {
    components: {
        cards, cardsRight
    },
    data() {
        return {
            Records:false,//处理记录
            drawer:false,
            showBoxtit: '',
            visible: false,
            input: '',
            requestApi: DevList,
            total:0,
            columns: [
                { key: 'T_tp_name', label: '类型',width:150},
                { key: 'T_sn', label: 'SN',width:150},
                { key: 'T_DS_name', label: '名称',width:150},
                { key: 'T_Text', label: '处理措施',width:150},
                { key: 'T_State', label: '是否处理',name: 'T_State',width:100},
                { key: 'T_Ut', label: '时间',width:200},
                { key: 'T_Remark', label: '内容',width:300},
                { key: 'option', label: '操作',name: 'right'},
            ],
            initParam:{
                T_name: '',
                T_tp: '',
                T_handle:'1',
                Time_start: '',
                Time_end: '',
                T_admin: 0,
                T_history: 0,
                page: 1,
                page_z: 10
            },
            PageParam:{//处理参数
                T_id: '',
				T_Text: '',
				T_time:'',
				T_history:''
            },
            multipleSelection: [], // 存储勾选状态的数组
            Rescolumns: [//记录
                { key: 'T_Log', label: '详细信息'},
            ],
        }
    },
    mounted(){
        this.setTimefn()

        this.initParam = {...this.initParam,...this.$route.query}
        console.log('11',this.initParam)
        this.$nextTick(()=>{
            this.$refs.tableRef.callPropFunction()
        })
    },
    methods: {
        setTimefn() { //获取当天0点与当前时间 
			const now = new Date();  
			const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0); 
			const min = midnight.getTime()//今天0点时间戳，
			const max = now.getTime(); //当前时间戳
			this.initParam.Time_start = formatDate(min)
			this.initParam.Time_end = formatDate(max)
			this.$refs.drawersRef.TimeData = [formatDate(min),formatDate(max)]
			this.$emit("pick", this.$refs.drawersRef.TimeData)
		}, 
        searchFun(data){
            this.initParam = {...data}
            console.log('搜索1',data,this.initParam,this.$refs.tableRef)
            // this.$refs.tableRef.initParam = data
            this.$nextTick(()=>{
                this.$refs.tableRef.callPropFunction()
            })
        },
        DowDataExcel() {//下载excel
			if (this.total == 0) {
				this.$message.error('当前导出没有数据哦！')
				return
			} else {
				const loading = this.$loading({
					lock: true,
					text: '正在整理数据，请稍后...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				DevicExcel(this.initParam).then(res => {
					loading.close()
					Axios({
						method: 'get',
						url: res.data.Data,
						responseType: 'blob'
					}).then(resBlob => {
						fileDownload(resBlob.data, `${new Date().getTime()}.xlsx`);
					});
				})
			}
		},

        SetChuli(e) { //处理按钮
			this.PageParam = {
				T_id: e.Id,
				T_Text: '',
				T_time: e.T_Ut,
				T_history: e.T_history
			}
			this.showBoxtit = '数据处理'
            this.visible = true
            console.log('处理',this.PageParam,e)
		},
        async asyncPro() {//批量处理提交
			var obj = {
				T_id: null,
				T_Text: this.PageParam.T_Text,
			}
			var k = 0
			for (let i = 0; i < this.multipleSelection.length; i++) {
				obj.T_id = this.multipleSelection[i].Id
				obj.T_time = this.multipleSelection[i].T_Ut
                obj.T_history = this.PageParam.T_history
                console.log('批量处理---'+i,obj)
				let objs = await this.setDevicEditApi(obj);
				if (objs) {
					k = k + 1
				}
				if (this.multipleSelection.length == k) {
					this.$message.success('批量处理完成')
					this.PageParam.page = 1
					this.$refs.tableRef.callPropFunction()
					this.visible = false
					this.PageParam.T_Text = ''
				}
			}
		},
        async subChuli(){//单个处理
			var val = await this.setDevicEditApi(this.PageParam)
			if (val.data.Code == 200) {
				this.initParam.page = 1
				this.$refs.tableRef.callPropFunction()
				this.$message.success('处理成功');
				this.visible = false
				this.PageParam.T_Text = ''
			}
		},
        setDevicEditApi(obj) {//处理提交接口
			return new Promise(resolve => {
				DevicEdit(obj).then(res => {
					resolve(res)
				})
			})
		},
        onSubmitFun(){//触发提交按钮
            if (this.showBoxtit=='批量操作') {
				this.asyncPro()
			} else {
				this.subChuli()
			}
        },
        SetDel(obj) { //删除
			console.log('删除', obj)
			this.$confirm('此操作将执行删除, 是否继续?', '提示', {
				confirmButtonText: '确定删除',
				cancelButtonText: '取消',
				type: 'warning',
				center: true
			}).then(() => {
				DevicDel({
					T_id: obj.Id,
					T_time: obj.T_Ut,
					T_history: obj.T_history
				}).then(res => {
					if (res.data.Code === 200) {
						this.$refs.tableRef.callPropFunction()
						this.$message.success('操作成功，已删除')
					}
				})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});
			});
		},
        Setjilu(e) { //记录
            let array = [...e.T_Log]
            let setArr = []
            array.forEach(element => {
                setArr.push({T_Log:element})
            });
			this.Records = true
           this.$nextTick(()=>{
			    console.log(setArr)
                this.$refs.RestableRef.tableData = setArr
           })
		},
        searchfun() {
            this.drawer = true
            this.$refs.drawersRef.SetWarning()
        },
        checkboxFun(data) {//勾选
            this.multipleSelection = data
            console.log('勾选1',data, this.multipleSelection)

        },
        ResDateFilter(data) {//批量处理
            console.log('勾选',this.multipleSelection,this.multipleSelection.length)
            if(this.multipleSelection.length){
                console.log('批量处理',this.multipleSelection)
                this.showBoxtit = data
                this.visible = true
            }else{
                this.$message.error('批量操作至少选择一项哦')
                return
            }
        }
    }
}
</script>
<style scoped>
::v-deep .el-drawer {
    background-color: #082c5f !important;
    background: url('../../assets/img/bg3.png') no-repeat;
    background-size: auto 100%;
    background-position: bottom center;
}

::v-deep .el-drawer__header {
    color: #5fc5ff;
    justify-content: center;
}

::v-deep .el-drawer__header>:first-child {
    flex: 1;
    font-size: 20px;
    font-weight: bold
}
</style>
<style scoped>
::v-deep .el-input__inner {
    background-color: #183d7d !important;
    border: 1px solid #24509b;
    color: #5fc5ff;
    text-align: center;
}
::v-deep .el-form-item__label{
    color: #5fc5ff !important;

}
::v-deep .el-input__inner:focus {
    border: 1px solid #24509b;
}
.model-btn{
    width:150px;
    height: 150px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transform: scale(1.1);
    transition: all .5s;
    margin: 80px auto 0 auto;
}
.model-btn:hover{
    transform: scale(1.2);
}
.model-btn>img{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    z-index: 1;
}
.model-btn>label{
    display: block;
    width: 40px;
    text-align: justify;
    text-align-last: justify;
    z-index: 2;
    letter-spacing: 2px;
    cursor: pointer;
    font-weight: bold;
}

</style>

<style lang="scss">
@import url('../../assets/scss/alarmData.scss');
.alarmData {
    overflow-x: hidden;
}
.alarmData-titRight {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
}
.alarmData-main-item-main-bottom-right-item{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.alarmData-main-item-main-bottom-right-item>span{
    font-size: 12px;
    transform: scale(0.8);
}
</style>